import { template as template_20f6d8cf02584c279cbab2b57c649067 } from "@ember/template-compiler";
const FKControlMenuContainer = template_20f6d8cf02584c279cbab2b57c649067(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

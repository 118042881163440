import { template as template_cab22b9087c8469193e36ee14a8977c4 } from "@ember/template-compiler";
const FKLabel = template_cab22b9087c8469193e36ee14a8977c4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
